/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'MessageList',
  components: {
    Scroll: Scroll
  },
  data: function data() {
    return {
      messageList: [],
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      emptyShow: false
    };
  },
  computed: {},
  methods: {
    /** function 下拉刷新
     **/
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.messageList = [];
      this.pageIndex = 1;
      this.getMessageList();
    },

    /** function 上拉加载
     **/
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getMessageList();
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    getMessageList: function getMessageList() {
      var _this = this;

      this.emptyShow = false;
      this.$api.Message.getMessageData({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.updateScroll(false);
        } else {
          var _this$messageList;

          (_this$messageList = _this.messageList).push.apply(_this$messageList, _toConsumableArray(data.records));

          _this.pageIndex = data.pageIndex;
          _this.totalPage = data.totalPage;

          if (_this.messageList.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll(false);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    recordClick: function recordClick(value) {
      this.$router.push({
        name: 'Message',
        query: {
          xh: value
        }
      });
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.getMessageList();
  },
  mounted: function mounted() {// this.pushHistory();
    // window.onpopstate = () => {
    //     this.$router.push('/');
    // }
  },
  destroyed: function destroyed() {}
};